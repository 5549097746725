var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageHeader'),_c('b-overlay',{attrs:{"show":_vm.isLoading}},[_c('b-card',{staticClass:"mb-4"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var failed = ref.failed;
return [_c('b-toast',{attrs:{"visible":failed,"title":"Warning","variant":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('messages.invalid_form'))+" ")]),_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[(_vm.isOwner)?_c('ValidationProvider',{attrs:{"name":"masterId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-sm":"12","label-cols-lg":"2","label":"เลือก Master","state":errors[0] ? false : null}},[_c('MasterSelectInput',{attrs:{"value":_vm.form.masterId,"hide-label":""},on:{"update":function (masterId) { return _vm.form.masterId = masterId; }}})],1)]}}],null,true)}):_vm._e(),(_vm.isOwner)?_c('b-form-group',{attrs:{"label-cols-sm":"12","label-cols-lg":"2","label":"เลือก Agent"}},[_c('AgentsSelectInput',{attrs:{"value":_vm.form.agentId,"master-id":_vm.form.masterId,"has-select-all":true,"hide-label":""},on:{"update":function (agentId) { return _vm.form.agentId = agentId; }}})],1):_vm._e(),_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"ชื่อแคมเปญ *","label-cols-sm":"12","label-cols-lg":"2"}},[_c('b-form-input',{attrs:{"id":"name","state":errors[0] ? false : null,"aria-invalid":false},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"targetUrl","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"ลิงค์ปลายทาง *","label-cols-sm":"12","label-cols-lg":"2"}},[_c('b-form-input',{attrs:{"id":"targetUrl","type":"url","state":errors[0] ? false : null,"aria-invalid":false},model:{value:(_vm.form.targetUrl),callback:function ($$v) {_vm.$set(_vm.form, "targetUrl", $$v)},expression:"form.targetUrl"}})],1)]}}],null,true)}),_c('hr'),_c('ValidationProvider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"หัวข้อ *","label-cols-sm":"12","label-cols-lg":"2"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" หัวข้อ * "),_c('a',{directives:[{name:"b-modal",rawName:"v-b-modal.example",modifiers:{"example":true}}],attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();}}},[_c('i',{staticClass:"uil uil-info-circle"})])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"title","state":errors[0] ? false : null,"aria-invalid":false},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"คำอธิบาย *","label-cols-sm":"12","label-cols-lg":"2"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" คำอธิบาย * "),_c('a',{directives:[{name:"b-modal",rawName:"v-b-modal.example",modifiers:{"example":true}}],attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();}}},[_c('i',{staticClass:"uil uil-info-circle"})])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"description","state":errors[0] ? false : null,"aria-invalid":false},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"image","rules":_vm.trackingLinkId ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"รูปภาพ *","label-cols-sm":"12","label-cols-lg":"2"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" รูปภาพ * "),_c('a',{directives:[{name:"b-modal",rawName:"v-b-modal.example",modifiers:{"example":true}}],attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();}}},[_c('i',{staticClass:"uil uil-info-circle"})])]},proxy:true}],null,true)},[_c('b-form-file',{attrs:{"id":"image","accept":"image/*","state":errors[0] ? false : null,"aria-invalid":false},model:{value:(_vm.form.image),callback:function ($$v) {_vm.$set(_vm.form, "image", $$v)},expression:"form.image"}})],1)]}}],null,true)}),(_vm.imageUrl)?_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"offset-lg":"2"}},[_c('b-img',{staticStyle:{"max-width":"400px"},attrs:{"src":_vm.imageUrl}})],1)],1):_vm._e(),(_vm.trackingLinkId)?_c('b-form-group',{attrs:{"label":"ลิงค์","label-cols-sm":"12","label-cols-lg":"2"}},[_c('b-form-input',{attrs:{"id":"targetUrl","value":_vm.trackingLinkUrl,"readonly":"","type":"url","aria-invalid":false}})],1):_vm._e(),_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"mr-2",attrs:{"to":"/tracking-link/list","variant":"light"}},[_vm._v(" "+_vm._s(_vm.$t('buttons.back'))+" ")]),_c('b-overlay',{staticClass:"d-inline-block",attrs:{"show":_vm.isLoading,"rounded":"","spinner-small":""}},[_c('b-button',{attrs:{"type":"submit","variant":"primary","block":""}},[_vm._v(" "+_vm._s(_vm.$t('buttons.save'))+" ")])],1)],1)],1)]}}])}),_c('b-modal',{attrs:{"id":"example","size":"lg","title":"ตัวอย่างการแสดงข้อมูล","ok-only":""}},[_c('img',{staticClass:"w-100",attrs:{"src":require("@assets/images/tracking-link-information-example.jpg")}})])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }