<template>
  <div>
    <PageHeader />
     <b-overlay :show="isLoading">
      <b-card class="mb-4">
        <ValidationObserver
          ref="form"
          v-slot="{ handleSubmit, failed }"
        >
          <b-toast
            :visible="failed"
            title="Warning"
            variant="warning"
          >
            {{ $t('messages.invalid_form') }}
          </b-toast>
          <form
            autocomplete="off"
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <ValidationProvider
                v-if="isOwner"
                v-slot="{ errors }"
                name="masterId"
                rules="required"
            >
              <b-form-group
                  label-cols-sm="12"
                  label-cols-lg="2"
                  label="เลือก Master"
                  :state="errors[0] ? false : null"
              >
                <MasterSelectInput
                    :value="form.masterId"
                    hide-label
                    @update="masterId => form.masterId = masterId"
                />
              </b-form-group>
            </ValidationProvider>
            <b-form-group
                v-if="isOwner"
                label-cols-sm="12"
                label-cols-lg="2"
                label="เลือก Agent"
            >
              <AgentsSelectInput
                  :value="form.agentId"
                  :master-id="form.masterId"
                  :has-select-all="true"
                  hide-label
                  @update="agentId => form.agentId = agentId"
              />
            </b-form-group>

            <ValidationProvider
                v-slot="{ errors }"
                name="name"
                rules="required"
            >
              <b-form-group
                  label="ชื่อแคมเปญ *"
                  label-cols-sm="12"
                  label-cols-lg="2"
              >
                <b-form-input
                    id="name"
                    v-model="form.name"
                    :state="errors[0] ? false : null"
                    :aria-invalid="false"
                ></b-form-input>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
                v-slot="{ errors }"
                name="targetUrl"
                rules="required"
            >
              <b-form-group
                  label="ลิงค์ปลายทาง *"
                  label-cols-sm="12"
                  label-cols-lg="2"
              >
                <b-form-input
                    id="targetUrl"
                    v-model="form.targetUrl"
                    type="url"
                    :state="errors[0] ? false : null"
                    :aria-invalid="false"
                ></b-form-input>
              </b-form-group>
            </ValidationProvider>

            <hr />

            <ValidationProvider
                v-slot="{ errors }"
                name="title"
                rules="required"
            >
              <b-form-group
                  label="หัวข้อ *"
                  label-cols-sm="12"
                  label-cols-lg="2"
              >
                <template #label>
                  หัวข้อ *
                  <a v-b-modal.example href="#" @click.prevent>
                    <i class="uil uil-info-circle"></i>
                  </a>
                </template>
                <b-form-input
                    id="title"
                    v-model="form.title"
                    :state="errors[0] ? false : null"
                    :aria-invalid="false"
                ></b-form-input>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
                v-slot="{ errors }"
                name="description"
                rules="required"
            >
              <b-form-group
                  label="คำอธิบาย *"
                  label-cols-sm="12"
                  label-cols-lg="2"
              >
                <template #label>
                  คำอธิบาย *
                  <a v-b-modal.example href="#" @click.prevent>
                    <i class="uil uil-info-circle"></i>
                  </a>
                </template>
                <b-form-input
                    id="description"
                    v-model="form.description"
                    :state="errors[0] ? false : null"
                    :aria-invalid="false"
                ></b-form-input>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
                v-slot="{ errors }"
                name="image"
                :rules="trackingLinkId ? '' : 'required'"
            >
              <b-form-group
                  label="รูปภาพ *"
                  label-cols-sm="12"
                  label-cols-lg="2"
              >
                <template #label>
                  รูปภาพ *
                  <a v-b-modal.example href="#" @click.prevent>
                    <i class="uil uil-info-circle"></i>
                  </a>
                </template>
                <b-form-file
                    id="image"
                    v-model="form.image"
                    accept="image/*"
                    :state="errors[0] ? false : null"
                    :aria-invalid="false"
                />
              </b-form-group>
            </ValidationProvider>

            <b-row v-if="imageUrl" class="mb-3">
              <b-col offset-lg="2">
                <b-img :src="imageUrl" style="max-width: 400px" />
              </b-col>
            </b-row>

            <b-form-group
                v-if="trackingLinkId"
                label="ลิงค์"
                label-cols-sm="12"
                label-cols-lg="2"
            >
              <b-form-input
                  id="targetUrl"
                  :value="trackingLinkUrl"
                  readonly
                  type="url"
                  :aria-invalid="false"
              ></b-form-input>
            </b-form-group>

            <div class="text-right">
              <b-button
                  class="mr-2"
                  to="/tracking-link/list"
                  variant="light"
              >
                {{ $t('buttons.back')}}
              </b-button>
              <b-overlay
                :show="isLoading"
                rounded
                spinner-small
                class="d-inline-block"
              >
                <b-button
                  type="submit"
                  variant="primary"
                  block
                >
                  {{ $t('buttons.save') }}
                </b-button>
              </b-overlay>
            </div>
          </form>
        </ValidationObserver>
        <b-modal id="example" size="lg" title="ตัวอย่างการแสดงข้อมูล" ok-only>
          <img
              src="@assets/images/tracking-link-information-example.jpg"
              class="w-100"
          />
        </b-modal>
      </b-card>
     </b-overlay>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  page: {
    title: 'ลิงค์การตลาด',
  },
  components: {
    MasterSelectInput: () => import('@components/master/master-select-input'),
    AgentsSelectInput: () => import('@components/agents/agents-select-input'),
  },
  data() {
    return {
      isLoading: false,
      imageUrl: null,
      form: {
        agentId: null,
        masterId: null,
        name: null,
        targetUrl: '',
        title: '',
        description: '',
        image: null
      },
    }
  },
  computed: {
    ...mapGetters(['isAgent', 'isOwner', 'isMaster', 'trackingLinkDetail', 'userInfo']),
    trackingLinkId() {
      return this.$route.params.id === 'create' ? null : this.$route.params.id
    },
    trackingLinkUrl() {
      if(!this.trackingLinkDetail) {
        return ''
      }
      const baseUrl = process.env.VUE_APP_TRACKING_BASE_URL.endsWith('/') ? process.env.VUE_APP_TRACKING_BASE_URL : process.env.VUE_APP_TRACKING_BASE_URL + '/'
      return baseUrl + this.trackingLinkDetail?.trackingCode
    }
  },
  async created() {
    if (this.trackingLinkId) {
      this.isLoading = true
      await this.fetchTrackingLink(this.trackingLinkId)
      await this.setData()
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions(['createTrackingLink', 'updateTrackingLink', 'fetchTrackingLink']),
    async setData() {
      this.form = {
        masterId: this.trackingLinkDetail.masterId,
        agentId: this.trackingLinkDetail.agentId,
        name: this.trackingLinkDetail.name,
        targetUrl: this.trackingLinkDetail.targetUrl,
        title: this.trackingLinkDetail.title,
        description: this.trackingLinkDetail.description,
      }
      this.imageUrl = this.trackingLinkDetail.image

      //
      let master = this.userInfo.masters.find((master) => master.id === this.trackingLinkDetail.agentId)
      if(master) {
        this.form.agentId = null
        this.form.masterId = master.id
      } else {
        this.userInfo.masters.forEach((master) => {
          const agent = master.children.find((agent) => agent.id === this.trackingLinkDetail.agentId)
          if(agent) {
            this.form.agentId = agent.id
            this.form.masterId = master.id
          }
        })
      }
    },
    async onSubmit() {
      this.isLoading = true
      if(!this.trackingLinkId) {
        if(this.isAgent) {
          this.form.agentId = this.userInfo.agentId
        } else if(this.isMaster) {
          this.form.masterId = this.userInfo.agentDetail.parentId
          this.form.agentId = null
        } else if(this.form.agentId === '') {
          this.form.agentId = null
        }
      }

      if(this.trackingLinkId) {
        await this.updateTrackingLink({
          id: this.trackingLinkId,
          data: this.form
        })
        await this.fetchTrackingLink(this.trackingLinkId)
        await this.setData()
      } else {
        await this.createTrackingLink(this.form)
      }
      this.isLoading = false
    },
  },
}
</script>
